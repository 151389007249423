/**
 * These theme values will override the base theme.
 *
 * We're passing these values to Layout component;
 * The Layout component merges these values with the
 * base theme.
 *
 */

const customThemeColors = {
  colors: {
    //background: `#27272A`,
    //headerBg: `transparent`,
    footerBg: `transparent`
  }
}
export default customThemeColors
