import React from 'react'
import { Container, Box } from 'theme-ui'
import Divider from '@solid-ui-components/Divider'
import ContentText from '@solid-ui-components/ContentText'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const FeaturesWithPhotoBlock03 = ({ content: { text } }) => (
  <Container sx={{ textAlign: 'left' }}>
    <Box>
      {text && (
        <ContentText content={text} />
      )}
    </Box>
    <Divider space={2} />
    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
      {/* First Row */}
      <Box sx={{ flex: '1 1 auto', maxWidth: '50%', p: 2 }}>
        <Box sx={{ position: 'relative', width: '100%', height: '50vh' }}>
          <iframe src="https://cyberesia.com" style={{ width: '100%', height: '100%', borderRadius: '15px' }}></iframe>
        </Box>
      </Box>
      <Box sx={{ flex: '1 1 auto', maxWidth: '50%', p: 2 }}>
        <Box sx={{ position: 'relative', width: '100%', height: '50vh' }}>
          <iframe src="https://bots.cyberesia.com" style={{ width: '100%', height: '100%', borderRadius: '15px' }}></iframe>
        </Box>
      </Box>
      {/* Second Row */}
      <Box sx={{ flex: '1 1 auto', maxWidth: '50%', p: 2 }}>
        <Box sx={{ position: 'relative', width: '100%', height: '50vh' }}>
          <iframe src="https://ikiblast.com" style={{ width: '100%', height: '100%', borderRadius: '15px' }}></iframe>
        </Box>
      </Box>
      <Box sx={{ flex: '1 1 auto', maxWidth: '50%', p: 2 }}>
        <Box sx={{ position: 'relative', width: '100%', height: '50vh' }}>
          <iframe src="https://erenials.com" style={{ width: '100%', height: '100%', borderRadius: '15px' }}></iframe>
        </Box>
      </Box>
    </Box>
    <Divider space={2} />
  </Container>
)

export default WithDefaultContent(FeaturesWithPhotoBlock03)
